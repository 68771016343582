import React from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';

class Main extends React.Component {
  navBarStyle = {
    display: 'flex',
    position: 'fixed',
    top: '25px',
    left: '50%',
    transform: 'translate(-50%, 0%)',
  };

  render() {
    return (
      <div style={this.navBarStyle}>
        <NavigationBar />
      </div>
    );
  }
}

export default Main;

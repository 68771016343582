import React from 'react';

class NavigationBar extends React.Component {
  barStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
    backgroundColor: '#EAE7E0',
    padding: '0px 30px',
    borderRadius: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
  };

  orderedTabNames = [
    'work',
    'about',
    'resume',
  ];

  constructor() {
    super();
    this.state = {
      selectedTab: 0,
    };
    this.handleTabTap = this.handleTabTap.bind(this);
  }

  handleTabTap(tabIndex) {
    this.setState({
      selectedTab: tabIndex,
    });
  }

  render() {
    const { selectedTab } = this.state;

    const orderedTabStyles = this.orderedTabNames.map((_, index) => (
      {
        color: index === selectedTab ? '#347528' : 'black',
        border: 'none',
        background: 'none',
        padding: '0',
        height: '20px',
        cursor: 'pointer',
      }
    ));

    return (
      <div style={this.barStyle}>
        {
          this.orderedTabNames.map((name, index) => (
            <button key={name} type="button" className="navbarTab" onClick={() => { this.handleTabTap(index); }} style={orderedTabStyles[index]}>
              {name}
            </button>
          ))
        }
      </div>
    );
  }
}

export default NavigationBar;
